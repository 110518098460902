import React, { Component, Fragment } from 'react';
// import {Helmet} from "react-helmet";
// import Safe from 'react-safe';
// import ReactGA from 'react-ga';

//language file
import { translate } from '../../i18n/translate';

// import { getContestState } from '../../util';
// import '../../styles/pages/_rules.scss';

const en_id = '5a437557-8805-49e7-ad04-33e955fd54cc';
const fr_id = 'dedd7ef9-f321-4bfa-bd72-a88e36e5d8c6';

class Privacy extends Component{

    componentDidMount(){
      const _id = this.props.lang==='fr-CA' ? fr_id : en_id;
      eval(`OneTrust.NoticeApi.LoadNotices(["https://privacyportalde-cdn.onetrust.com/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/privacy-notices/${_id}.json"])`);
    }

    render(){
        const langPreferance = this.props.lang;
        const _id = langPreferance==='fr-CA' ? fr_id : en_id;
        // const contestState = getContestState();
        return(
            <Fragment>
                <div id="main" className="main-wrapper rules">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                  {/* <h1>{ translate('footer.link2') }</h1> */}
                                  <div id={`otnotice-${_id}`} className="otnotice"></div>
                                </div>
                            </div>
                        </div>
                </div>
            </Fragment>
        )
    }
}

export default Privacy;