import React, { Fragment, Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
// import Cookie from 'js-cookie';
// import { Helmet } from 'react-helmet';
import './App.scss';
import { I18nProvider, LOCALES } from './i18n'; //language files
// import ReactGA from 'react-ga'; //google analytics
import ScrollToTop from './component/ScrollToTop'; // scroll restoration
import ComingSoon from './component/pages/ComingSoon';
import Header from './component/header/Header';
import Footer from './component/header/Footer';
import Homepage from './component/pages/Homepage';
import CookiesPage from './component/pages/CookiesPage';
import Privacy from './component/pages/Privacy';
import Terms from './component/pages/Terms';
import { getContestState } from './util';

// import {useLocation} from "react-router-dom";

class App extends Component {

    state = {
      locale : window.reactInit.initlang,
      contestState : getContestState(),
      activeEN: window.reactInit.initlang===LOCALES.ENGLISH
    }

    //Language change
    langChangeHandle = lang => {
        if (process.env.REACT_APP_LANG_MODE==='switch'){
            this.setState({
                locale: lang
            })
    
            this.setState((prevState) => {
                return {activeEN: !prevState.activeEN}
            })
        } else {
            // this.setState({
            //     locale: lang
            // })
            const path = window.location.pathname.split('/');
            const basepath = path.filter(e=>e!=='fr' && e!=='');
            console.log('basepath',basepath.join('/'));

            if (lang===LOCALES.ENGLISH){
                
                //this.props.history.push('/');
                window.location = process.env.REACT_APP_EN_URL + '/' + basepath.join('/');
            } else {
                //this.props.history.push('/fr/');
                window.location = process.env.REACT_APP_FR_URL + '/' + basepath.join('/');
            }
        }

    }

    render(){
        console.log(this.state);
        return (
            <I18nProvider locale={this.state.locale}>
                <Router>
                    <ScrollToTop />
                    <Fragment>
                        {/* <Helmet>
                            <title>{this.state.locale === 'fr-CA' ? '' : 'Snack on with XBOX'}</title>
                        </Helmet> */}
                        <main className={this.state.locale}>
                            <a className="skip-to-content-link" href="#main">
                                Skip to content
                            </a>
                            <Header langChangeHandle={this.langChangeHandle} activeEN={this.state.activeEN} />
                            <Switch>
                                {/* home page */}
                                { this.state.contestState===-1 &&
                                    <Route exact path="/" render={(props)=><ComingSoon {...props} lang={this.state.locale} />}  />
                                }
                                { this.state.contestState===0 &&
                                    <Route exact path="/" render={(props)=><Homepage {...props} lang={this.state.locale} />} />
                                }
                                
                                <Route exact path="/cookies" render={(props) => <CookiesPage {...props} lang={this.state.locale} />}/>
                                <Route exact path="/privacy" render={(props) => <Privacy {...props} lang={this.state.locale} />}/>
                                <Route exact path="/terms" render={(props) => <Terms {...props} lang={this.state.locale} />}/>
                            </Switch>
                        </main>
                        <Footer lang={this.state.locale} />
                    </Fragment>
                </Router>
            </I18nProvider>
        );
    }
}

export default App;
